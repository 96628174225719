import request from '@/utils/request'

// 获取码率
export function getBitRate (data) {
  return request({
    url: '/user/getBitRate',
    data
  })
}

// 设置码率
export function setupBitRate (data) {
  return request({
    url: '/user/setupBitRate',
    data
  })
}
