<template>
  <Layout
    v-loading="loading"
    class="BitRate"
  >
    <el-form
      :model="formData"
      label-width="100px"
    >
      <el-form-item>
        默认码率上限
        <span
          v-if="!isEdit"
          class="number"
        >{{ formData.defaultBitRate }}</span>
        <el-input-number
          v-else
          v-model="formData.defaultBitRate"
          :min="0"
        />
        M/s
      </el-form-item>
      <br>
      <hr>
      <el-form-item>
        特殊码率上限
        <span
          v-if="!isEdit"
          class="number"
        >{{ formData.specialBitRate }}</span>
        <el-input-number
          v-else
          v-model="formData.specialBitRate"
          :min="0"
        />
        M/s
      </el-form-item>
      <el-form-item>
        <h4 style="margin:0">
          特殊码率使用用户为（须符合以下全部条件）：
        </h4>
        <el-checkbox-group
          v-if="isEdit"
          v-model="formData.setupModule"
        >
          <div>
            <el-checkbox
              style="width:140px;"
              :label="setupModule.operator"
            >
              运营商 (多选):
            </el-checkbox>
            <el-select
              v-model="formData.operator"
              style="width: 240px"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(item, index) in operatorList"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
          <div>
            <el-checkbox
              style="width:140px;"
              :label="setupModule.network"
            >
              网络 (多选):
            </el-checkbox>
            <el-select
              v-model="formData.network"
              multiple
              collapse-tags
              style="width: 240px"
            >
              <el-option
                v-for="(item, index) in networkList"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
          <div>
            <el-checkbox
              style="width:140px;"
              :label="setupModule.connectRoom"
            >
              机房 (多选):
            </el-checkbox>
            <el-select
              v-model="formData.connectRoom"
              style="width: 240px"
              multiple
              collapse-tags
            >
              <el-option
                v-for="(item, index) in fullGameRoomList"
                :key="index"
                :label="item.roomName"
                :value="item.roomId"
              />
            </el-select>
          </div>
          <div />
        </el-checkbox-group>
        <div v-else>
          <div class="rows">
            <div class="label">
              <i :class="`el-icon-circle-${formData.setupModule.includes(setupModule.operator) ? 'check' : 'close'}`" />
              运营商:
            </div>
            <div class="value">
              {{
                formData.operator.map(id => {
                  return operatorMap[id]
                }).join(' / ')
              }}
            </div>
          </div>
          <div class="rows">
            <div class="label">
              <i :class="`el-icon-circle-${formData.setupModule.includes(setupModule.network) ? 'check' : 'close'}`" />
              网络:
            </div>
            <div class="value">
              {{
                formData.network.map(id => {
                  return networkMap[id]
                }).join(' / ')
              }}
            </div>
          </div>
          <div class="rows">
            <div class="label">
              <i :class="`el-icon-circle-${formData.setupModule.includes(setupModule.connectRoom) ? 'check' : 'close'}`" />
              机房:
            </div>
            <div class="value">
              {{
                formData.connectRoom.map(id => {
                  return fullGameRoomMap[id]
                }).join(' / ')
              }}
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleEdit">
          {{ isEdit ? '保存' : '编辑' }}
        </el-button>
      </el-form-item>
    </el-form>
  </Layout>
</template>

<script>
import { getEngineRoomInfo } from '@/api/gameList'
import {
  getBitRate,
  setupBitRate
} from '@/api/bitRate.js'
import { mapState } from 'vuex'

const setupModule = {
  operator: 3100,
  network: 3200,
  connectRoom: 3300
}
export default {
  name: 'BitRate',
  data () {
    return {
      setupModule,
      isEdit: false,
      loading: false,
      formData: {
        defaultBitRate: 1,
        specialBitRate: 0,
        setupModule: [],
        operator: [],
        network: [],
        connectRoom: []
      },
      fullGameRoomList: [],
      fullGameRoomMap: {},
      networkList: [
        { name: '5G', value: 20 },
        { name: '4G', value: 13 }
      ],
      networkMap: {
        20: '5G',
        13: '4G'
      },
      operatorList: [
        { name: 'TRUE-H', value: 'TRUE-H' },
        { name: 'China Unicom', value: 'China Unicom' },
        { name: '中国移动', value: '中国移动' }
      ],
      operatorMap: {
        'TRUE-H': 'TRUE-H',
        'China Unicom': 'China Unicom',
        中国移动: '中国移动'
      }

    }
  },
  computed: {
    ...mapState('app', ['appId', 'platform'])
  },
  created () {
    this.getEngineRoomInfo()
    this.getBitRate()
  },
  methods: {
    getEngineRoomInfo () {
      getEngineRoomInfo({})
        .then(res => {
          if (res.code === 200) {
            this.fullGameRoomList = (res.data.list || []).map(({ roomId, roomName }) => {
              return {
                roomId: Number(roomId),
                roomName
              }
            })
            this.fullGameRoomMap = this.fullGameRoomList.reduce((map, item) => {
              map[item.roomId] = item.roomName
              return map
            }, {})
          }
        })
    },
    getBitRate () {
      this.loading = true
      getBitRate({
        appId: this.appId,
        platform: this.platform
      }).then(res => {
        if (res.code === 200) {
          const {
            defaultBitRate,
            specialBitRate,
            setupModule,
            network,
            connectRoom,
            operator
          } = res.data
          this.formData = {
            defaultBitRate,
            specialBitRate,
            setupModule: setupModule || [],
            network: network || [],
            connectRoom: connectRoom || [],
            operator: operator || []
          }
        }
      })
        .finally(() => {
          this.loading = false
        })
    },
    handleEdit () {
      if (!this.isEdit) {
        this.isEdit = true
      } else {
        this.setupBitRate()
          .then(() => {
            this.isEdit = false
          })
      }
    },
    setupBitRate () {
      this.loading = true
      return setupBitRate({
        appId: this.appId,
        platform: this.platform,
        ...this.formData
      })
        .then(res => {
          if (res.code === 200) {
            this.$message.success('更新成功')
            this.getBitRate()
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less">
  .BitRate {
    .number {
      color: blue;
    }
    .rows {
      display: flex;
      >div {
        display: inline-block;
        &.label {
          width: 100px;
        }
        &.value {
          color: blue;
        }
      }
    }
  }
</style>
